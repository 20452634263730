@import "../styles/modules/variables";

.zoeken_cards {
  min-height: 44px;
  padding: 10px 15px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    width: 600px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
}

.clickable {
  cursor: pointer;
}

.icon {
  padding: 2px;

  i {
    position: relative;
    bottom: 1px;

    &:before {
      font-size: 7px;
      color: var(--dark-black);
    }
  }
  .chevron_up {
    transform: rotate(-180deg);
    position: relative;
    top: 1px;
  }
}
.filter_active {
  background: var(--primary-brand-color);
  color: var(--white) !important;

  &:hover {
    background: var(--primary-brand-color) !important;
    color: var(--white) !important;
  }
}
.filter {
  &_icon {
    cursor: pointer;
    border-radius: 3px;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: var(--mid-grey);
      color: var(--dark-black);
    }

    > i {
      font-size: 7px;
      -webkit-font-smoothing: none;
      margin-top: -1px;
    }
  }

  &_overlay {
    position: absolute;
    right: 0;
    bottom: -10px;
    transform: translate(0, 100%);
    background: white;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
    border: 1px solid var(--dark-black);
    border-radius: 4px;
    max-width: 300px;
    min-width: 300px;
    white-space: initial;
    overflow: auto;

    i {
      font-size: 10px;
      color: var(--dark-black);
    }
  }

  &_title {
    font-family: var(--header-font);
    color: var(--dark-black);
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 1rem 1rem 0;
  }

  &_text {
    color: var(--dark-black);
    font-weight: 300;
    font-size: 13px;
    line-height: 17px;
    margin-right: 5px;
  }

  &_footer {
    background: var(--light-grey);
    padding: 10px 8px;
    text-align: right;
  }

  &_label {
    font-size: 13px !important;
    color: var(--dark-black) !important;
  }
}
.loader_correction {
  div[title="loader"] {
    left: 0;
  }
}
