// @import "../../styles/modules/mixins";

$phone: "(max-width: 480px)";
$tablet-portrait: "(max-width: 767px)";
$tablet-landscape: "(min-width: 768px) and (max-width: 979px)";
$large-desktop: "(min-width: 992px)";
$non-retina: "(-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2),(webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5),(min-resolution: 144dpi),(min-resolution: 1.5dppx)";

/* Bootstrap breakpoint */
$xxl-screens: "(min-width: 2300px)";
$extra-large-screens: "(min-width: 1200px) and (max-width: 1268px)";
$large-screens-card-padding: "(min-width: 993px) and (max-width: 1285px)";
$large-screens: "(min-width: 993px) and (max-width: 1080px)";
$medium-screens: "(max-width: 992px)";
$small-screens: "(max-width: 767px)";
$extra-small-screens: "(max-width: 576px)";
$no-more-resize: "(max-width: 992px)";

/* Specific phone breakpoints */

$ipad-portrait: "(min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)";
$iphone5-portrait: "(min-device-width: 320px)  and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)";
$iphone5-landscape: "(min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)";
$iphone6-portrait: "(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)";
$iphone6-landscape: "(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)";
$iphone6Plus-portrait: (
  "(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)"
);
$iphone6Plus-landscape: (
  "(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)"
);
$galaxys5-portrait: (
  "(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
);
$galaxys5-landscape: (
  "(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
);

@mixin breakpoint($media) {
  @media only screen and #{$media} {
    @content;
  }
}

.margin_correction {
  margin-top: -5px;
  height: 100%;

  input {
    margin-top: 5px;
    z-index: initial !important;
    height: 15px;
  }
}

.emptyGrid {
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .text {
    text-align: center;
  }
}

.progressBar {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 10%;
  @include breakpoint($medium-screens) {
    margin: 0 auto;
    margin-left: 44px;
    margin-right: 40px;
    max-width: 800px;
  }
}

.button_container {
  div:nth-child(1) {
    width: auto !important;

    div {
      width: 20px !important;
    }
  }
}
