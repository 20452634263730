$color-dark-gray: rgb(85, 85, 85);
$tag-text-color: white;
$font-size: 0.9em;
$padding: 0.15em;
$border-radius: 3px; //default adviesbox radius value
$max-width: 250px;
.tag_component {
  * {
    box-sizing: border-box;
  }
  > * {
    margin: $padding;
  }

  box-sizing: border-box;
  position: relative;
  padding: $padding * 2;
  overflow-y: auto;
  height: auto;
  min-height: 2.375em;
  max-width: $max-width;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: inherit;
  color: $tag-text-color;
  background: var(---white);
  border: 1px solid var(--border-color-normal);
  border-radius: $border-radius;

  &_input {
    display: block;
    height: calc(1.25rem + 5px);
    width: $max-width * 0.2 !important;
    padding: 0.2rem 0.4rem;
    line-height: 1.5;
    font-family: var(--source-sans-pro);
    font-size: inherit;
    font-weight: 400;
    color: var(--text-color);
    border: none;
    background: none !important;
    &:focus {
      border: none;
      outline: none;
    }
  }

  &_tag {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: inherit;
    border-radius: $border-radius;
    background: $color-dark-gray;
  }

  &_content {
    outline: 0;
    padding: 0 $padding * 2.5;
    white-space: nowrap;
    border: none;
  }

  &_remove {
    position: relative;
    height: $font-size * 2;
    width: $font-size * 2;
    font-size: inherit;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background: darken($color-dark-gray, 5%);
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: " ";
      height: $font-size - 0.1;
      width: 0.15em;
      background-color: $tag-text-color;
    }
    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
    &:after {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &_readonly {
      width: 0;
      &:before,
      &:after {
        content: "";
        width: 0;
      }
    }
  }

  &_options {
    position: absolute;
    z-index: 99 !important;
    max-width: $max-width;
    width: 95%;
    color: var(--text-color);
    font-size: inherit;
    &_active {
      background: var(--light-grey);
    }
  }

  &_options ul {
    margin: 0px -1px 4px -1px;
    padding: 0;
    list-style: none;
    border-radius: $border-radius;
    border: 1px solid #d1d1d1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    background: white;
  }

  &_options li {
    padding: 6px 8px;
    border-bottom: 1px solid #ddd;
  }

  &_options li:hover {
    cursor: pointer;
  }
}
