.mt_02 {
  margin-top: 2px;
}

.modal_footer {
  background: var(--light-grey);
  padding: 1.25rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
