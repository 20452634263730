.modal_footer {
  background: var(--light-grey);
  padding: 1.25rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.remove_overflow {
  > div {
    overflow-x: auto !important;
  }
}

.loading_indicator {
  height: 31px;
}
