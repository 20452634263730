/*-------------------------------------
    Save while navigatie buttons
--------------------------------------*/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes default {
  0% {
    color: var(--succes-green);
  }
  100% {
    color: var(--dark-grey);
    margin-left: -25px;
  }
}

@mixin font() {
  font-family: "AdviesboxIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}

.default,
.busy,
.failed,
.no-save {
  position: relative;
  margin-left: 1rem;

  &:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
  }
}

.default {
  padding: 3px 10px !important;
  border: 1px solid var(--save-btn-border) !important;
  background-color: var(--warning-bg-yellow) !important;
  border-radius: 3px;
  margin-left: -5px;
  margin-top: -3px;
  color: var(--text-color);

  &:hover,
  &:focus,
  &:active {
    border-color: var(--save-btn-border) !important;
    background-color: var(--save-btn-border) !important;
  }

  &:before {
    content: "\e923";
    @include font();
    position: relative;
    top: 2px;
    margin-right: 9px;
    font-size: 12px;
  }
}

.busy {
  margin-top: 4px;
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: -19px;
    border: 2px solid var(--mid-grey);
    border-top: 2px solid var(--darker-grey);
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
}

.saved {
  color: var(--succes-green);
  margin-top: 4px;
  animation: default 2s;
  animation-fill-mode: forwards;
  animation-delay: 2s;

  &:before {
    content: "\e924";
    @include font();
    position: relative;
    top: 2px;
    margin-right: 9px;
    font-size: 12px;
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }
}

.btn_danger {
  padding: 3px 10px !important;
  border: 1px solid var(--validation-red-text) !important;
  background-color: var(--validation-red-text) !important;
  border-radius: 3px;
  margin-left: -5px;
  margin-top: -3px;
  color: var(--text-color);

  &:hover,
  &:focus,
  &:active {
    border-color: var(--validation-red-hover) !important;
    background-color: var(--validation-red-hover) !important;
  }

  &:before {
    content: "\e925";
    @include font();
    position: relative;
    top: 2px;
    margin-right: 9px;
    font-size: 12px;
  }
}

.no_save {
  margin-top: 4px;
  color: var(--dark-grey);

  i {
    font-size: 12px;
    position: relative;
    top: 1px;
    margin-right: 9px;
  }
}
