.title {
  width: 100%;
  margin: 20px 0 30px 0.25rem !important;
}

.qr_img {
  text-align: center;
  img {
    max-width: 300px;
  }
}

.result {
  margin-top: 150px;
}

.custom {
  border: 0;
  background-color: #ececec !important;
  width: auto;
  height: auto;

  &.current {
    background-color: #ececec;

    color: green !important;
    border-radius: 100%;
    border: black 1px solid;
  }
}

.custom_step_index {
  z-index: 1;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ececec !important;
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  font-weight: bold;

  &.current {
    border: 1px solid black;
    border-radius: 20%;
  }

  &.active {
    color: #2cad4a;
    background-color: #d5efdb;
    border-color: #2cad4a;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: #2cad4a;
    }
  }
}
