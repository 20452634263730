.icon_container {
  position: relative;
  top: -1px;
  margin-left: 5px;
  i {
    font-size: 6px;
  }
}

.dropdown_item {
  min-width: 190px;
}

.organisatie_naam {
  font-style: italic;
  width: 250px;
  display: flex;
  justify-content: center;
}

.organisatie_balk {
  position: relative;
}

.organisatie_container {
  position: absolute;
  padding: 0;
  width: fit-content;
  margin: 0 calc(50% - 125px);
}
